import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getReviewFill = (current: number, value?: number) => {
  if (value) {
    if (value >= current) return "rgb(250 204 21)";

    return "white";
  }

  return "white";
};

export const getReviewEditFill = (
  current: number,
  preview: number | null,
  value?: number,
) => {
  if (preview) {
    if (preview >= current) return "rgb(254 240 138)";

    return "white";
  }
  if (value) {
    if (value >= current) return "rgb(250 204 21)";

    return "white";
  }

  return "white";
};

export const upperFirst = (string: string | null | undefined): string => {
  if (!string) return "";

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const listById = <T extends Record<string, unknown>>(
  list: T[],
  idKey: string,
): Record<string, T> =>
  list.reduce(
    (acc, item) => {
      const key = item[idKey] as string;
      acc[key] = item;

      return acc;
    },
    {} as Record<string, T>,
  );

export const formatMoney = ({
  amount = 0,
  hideCentsIfZero = true,
  integerCents = true,
}: {
  amount?: number | null;
  hideCentsIfZero?: boolean;
  integerCents?: boolean;
}) => {
  const moneyAmt = ((amount ?? 0) / (integerCents ? 100 : 1)).toFixed(2);

  return hideCentsIfZero
    ? `$${moneyAmt.replace(/[.,]00$/, "")}`
    : `$${moneyAmt}`;
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
